:root {
  --bg-color: #fafafa;
  --primary: #d0dc72;
  --primary-darker: #a7b157;
  --accent: #fa8072;
  --text: #808080;
}
.App {
  text-align: center;
  flex: 1;
  display: flex;
}

h3,
h4 {
  color: var(--text);
  margin: 5px;
}

h6 {
  color: var(--text);
  margin: 0px 5px 5px 5px;
}

.App-root {
  background-color: var(--bg-color);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  height: 10vh;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--bg-color);
  padding-top: 2vh;
  padding-bottom: 3vh;
}

.App-main-hide-header {
  padding-top: 5vh;
}

.App-chart {
  flex: 1;
}

.App-link {
  color: #61dafb;
}
